import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Inbox', url: '/tabs/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/tabs/folder/Outbox', icon: 'paper-plane' },
    { title: 'Login', url: '/login', icon: 'paper-plane' },
  ];
  constructor() {}
}
